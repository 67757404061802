import React, {useState, useEffect} from 'react';
import CallToAction from '../../components/CallToAction/CallToAction';
import Aux from '../../hoc/Aux/Aux';
import CampaignFlow from '../../components/UI/CampaignFlow/CampaignFlow';
import CallToActionPlaceholder from "../../components/UI/Placeholder/CallToActionPlaceholder/CallToActionPlaceholder";
import {cleanLocationProp} from "../../shared/utility";
import {Row, Col} from 'reactstrap';
import axios from '../../axios-base';
import {Helmet} from "react-helmet";
import { useLocation } from 'react-router-dom';
import KeyVisual from "../../components/UI/ResponsiveImageSet/ResponsiveImageSet";
import KeyVisualXs from '../../assets/images/keyvisual-xs-rgb.webp';
import KeyVisualLg from '../../assets/images/keyvisual-lg-rgb.webp';
import FundingVisual from '../../assets/images/crowdvoting-foerdersummen-visualisierung-inline-rgb.svg';

function Home (props) {
    const [ctas, setCtas] = useState(null);
    let location = useLocation();
    useEffect(() => {
        axios.get('/cta/', {
            params: {
                page: cleanLocationProp(location.pathname)
            }})
            .then(response => {
                setCtas(response.data);
            })
            .catch(error => {
                this.setState({error: true});
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

        let cta = (
            <React.Fragment>
                <Col sm="6" className="my-3 d-flex align-items-start flex-column"><CallToActionPlaceholder/></Col>
                <Col sm="6" className="my-3 d-flex align-items-start flex-column"><CallToActionPlaceholder/></Col>
                <Col sm="6" className="my-3 d-flex align-items-start flex-column"><CallToActionPlaceholder/></Col>
                <Col sm="6" className="my-3 d-flex align-items-start flex-column"><CallToActionPlaceholder/></Col>
            </React.Fragment>
        );
        if (ctas){
            cta = ctas.map((cta, key)=> (
                <React.Fragment key={key} >
                    {key === 2 ?
                        <Col sm="6" className="my-3 d-flex justify-content-center">
                            <img
                                src={FundingVisual}
                                className="img-fluid"
                                alt="Key Visual" />
                        </Col>
                    : null}
                    <Col sm="6" className="my-3 d-flex align-items-start flex-column">
                        <CallToAction
                            headline={cta.headline}
                            copy={cta.copy}
                            buttonText={cta.buttonText}
                            buttonColor={cta.buttonColor}
                            linkUrl={cta.linkUrl}
                            outline={cta.outline}
                            buttonVisibility={cta.buttonVisibility}
                        />
                    </Col>
                </ React.Fragment>
            ));
        }

        return (
            <Aux>
                <Helmet>
                    <title>{`${process.env.REACT_APP_PROJECT_TITLE}: Willkommen`}</title>
                </Helmet>
                <Row>
                    <Col className="px-0">
                        <KeyVisual imageXs={KeyVisualXs} imageLg={KeyVisualLg} />
                    </Col>
                </Row>
                <Row>
                    {cta}
                </Row>
            </Aux>
        );
    }

export default Home;
