import React, {useState, useEffect} from 'react';
import Aux from '../../hoc/Aux/Aux'
import Input from '../../components/UI/Input/FormInput';
import CallToAction from '../../components/CallToAction/CallToAction';
import {checkValidity, sendVirtualPageView} from "../../shared/utility";
import axios from '../../axios-base';
import {cleanLocationProp} from "../../shared/utility";
import {Col, Row, Button, Form, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {connect} from 'react-redux';
import NewMediaUpload from "../MediaUpload/NewMediaUpload";
import CallToActionPlaceholder from "../../components/UI/Placeholder/CallToActionPlaceholder/CallToActionPlaceholder";
import {Helmet} from "react-helmet";
import {useHistory} from 'react-router-dom';
import KeyVisual from "../../components/UI/ResponsiveImageSet/ResponsiveImageSet";
import KeyVisualXs from "../../assets/images/keyvisual-xs-rgb.webp";
import KeyVisualLg from "../../assets/images/keyvisual-lg-rgb.webp";
import classes from "../MediaUpload/NewMediaUpload.module.scss";
import SignUpPreview from "./SignUpPreview/SignUpPreview";
import SignUpPreviewList from "./SignUpPreviewList/SignUpPreviewList";

function SignUp(props) {

    const [projectForm, setProjectForm] = useState({});
    const [mediaObjects, setMediaObjects] = useState([]);
    const [formIsValid, setFormIsValid] = useState(false);
    const [projectId, setProjectId] = useState(null);
    const [cta, setCta] = useState(null);
    const [modal, setModal] = useState(false);
    const [systemMessage, setSystemMessage] = useState('');
    const [signUpSuccess, setSignUpSuccess] = useState(true);
    const [error, setError] = useState(false);


    let history = useHistory();

    const onUploadHandler = (responseArray) => {
        let newUrlArray = [];
        responseArray.forEach(item => {
            newUrlArray.push(item.transforms[0].location);
        });
        setMediaObjects(newUrlArray)
    };

    // TODO: Wie wirken sich die zahlreichen onChange-Events auf die Peformance aus? Ist es vlt. besser onSubmit zu nutzen?
    const onChangeHandler = (event, inputIdentifier) => {
        const updatedProjectForm = {
            ...projectForm
        };

        // Deep clone
        const updatedFormElement = {
            ...updatedProjectForm[inputIdentifier]
        };

        /**
         *  Check if form element is of type file, and if so set selectedFile state AND value
         *  else only set value
         */
        if (updatedFormElement.elementType === 'file' && !updatedFormElement.elementConfig.multiple) {
            updatedFormElement.selectedFile = event.target.files[0];
            updatedFormElement.value = event.target.value;
        } else if (updatedFormElement.elementType === 'file' && updatedFormElement.elementConfig.multiple) {
            updatedFormElement.selectedFiles = event.target.files;
            updatedFormElement.value = event.target.value;
        } else if (updatedFormElement.elementType === 'checkbox') {
            updatedFormElement.value = event.target.checked;
        } else {
            updatedFormElement.value = event.target.value;
        }
        let validity = checkValidity(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.valid = validity.isValid;
        updatedFormElement.errorMessage = validity.errorMessage ?? '';
        updatedFormElement.touched = true;
        updatedProjectForm[inputIdentifier] = updatedFormElement;

        let formIsValidValue = true;
        for (let inputIdentifier in updatedProjectForm) {
            formIsValidValue = updatedProjectForm[inputIdentifier].valid && formIsValidValue;
        }
        setProjectForm(updatedProjectForm);
        setFormIsValid(formIsValidValue)
    };

    const modalHandler = () => {
        setModal(prevState => {
            return !prevState;
        });
    };

    const reviewHandler = () => {
        modalHandler();
    };

    const onSubmitHandler = (event) => {
        event.preventDefault();
        // project-Objekt fuer axios POST bauen
        const fd = new FormData();
        const projectFormData = {};

        // Iterate through React state form and set values depending on elementType (i.e. 'file')
        for (let formElementIdentifier in projectForm) {

            if (projectForm[formElementIdentifier].elementType === 'file' && !projectForm[formElementIdentifier].elementConfig.multiple) {
                projectFormData[formElementIdentifier] = projectForm[formElementIdentifier].selectedFile;
                fd.append(formElementIdentifier, projectFormData[formElementIdentifier]);
            } else if (projectForm[formElementIdentifier].elementType === 'file' && projectForm[formElementIdentifier].elementConfig.multiple) {
                for (let [index, file] in Object.entries(projectForm[formElementIdentifier].selectedFiles)) {
                    fd.append(formElementIdentifier, projectForm[formElementIdentifier].selectedFiles[index]);
                    console.log(file);
                }
            } else {
                projectFormData[formElementIdentifier] = projectForm[formElementIdentifier].value;
                fd.append(formElementIdentifier, projectFormData[formElementIdentifier]);
            }

        }

        //Append image urls to the signup form
        fd.append("projectGallery", JSON.stringify(mediaObjects));


        // if user is authenticated patch project
        if (props.isAuthenticated) {
            const config = {
                headers: {'Authorization': "bearer " + props.token}
            };

            axios.patch(
                    // Todo: Echte Projekt-ID waehlen
                    '/projects/' + projectId, fd,
                    config
                )
                .then((response) => {
                    // Todo: Erfolgsnachricht anzeigen statt auf Startseite umzuleiten
                    setSystemMessage(response);
                    setSignUpSuccess(true);
                    history.push('/vielen-dank');
                })
                .catch(error => {
                    console.log(error);
                });
        // if user is NOT authenticated post new project
        } else {
            axios.post('/projects', fd)
                .then((response) => {
                    // Todo: Erfolgsnachricht anzeigen statt auf Startseite umzuleiten
                    history.push('/vielen-dank');

                })
                .catch(error => {
                    console.log(error);
                });
        }
    };

    useEffect(() => {
        axios.get('/cta/',{
            params: {
                page: cleanLocationProp(props.location.pathname)
            }})
            .then(response => {
                setCta(response.data[0]);
            })
            .catch(error => {
                setError(true);
            });

        axios.get('/options/')
            .then(response => {
                setProjectForm(response.data[0].projectForm);
            })
            .catch(error => {
                setError(true);
            });

        // Pre-fill form data for authenticated users with their respective project
        if (props.isAuthenticated) {
            const config = {
                headers: {'Authorization': "bearer " + props.token}
            };

            // Get user id from redux store dependent on authenticated user
            axios.get(
                '/users/' + props.userId,
                config
            )
                .then(response => {
                    const sampleData1 = { value: response.data.project.contestantAnswer1, valid: true };
                    const sampleData2 = { value: response.data.project.contestantAnswer2, valid: true };
                    const sampleData3 = { value: response.data.project.contestantZipcode, valid: true };
                    const sampleData4 = { value: response.data.project.contestantStreet, valid: true };
                    const sampleData5 = { value: response.data.project.contestantCity, valid: true };
                    const sampleData6 = { value: response.data.project.contestantName, valid: true };
                    const sampleData7 = { value: response.data.project.projectTitle, valid: true };
                    const sampleData8 = { value: response.data.salutation, valid: true };
                    const sampleData9 = { value: response.data.telephone, valid: true };
                    const sampleData10 = { value: response.data.firstname, valid: true };
                    const sampleData11 = { value: response.data.lastname, valid: true };
                    // Todo: Iterate through state in a more elegant way
                    this.setState(prevState => ({
                        projectForm: {
                            ...prevState.projectForm,
                            contestantAnswer1: { ...prevState.projectForm.contestantAnswer1, ...sampleData1 },
                            contestantAnswer2: { ...prevState.projectForm.contestantAnswer2, ...sampleData2 },
                            contestantZipcode: { ...prevState.projectForm.contestantZipcode, ...sampleData3 },
                            contestantStreet:  { ...prevState.projectForm.contestantStreet, ...sampleData4 },
                            contestantCity:    { ...prevState.projectForm.contestantCity, ...sampleData5 },
                            contestantName:    { ...prevState.projectForm.contestantName, ...sampleData6 },
                            projectTitle:      { ...prevState.projectForm.projectTitle, ...sampleData7 },
                            contactSalutation: { ...prevState.projectForm.contactSalutation, ...sampleData8 },
                            contactTelephone:  { ...prevState.projectForm.contactTelephone, ...sampleData9 },
                            contactFirstname:  { ...prevState.projectForm.contactFirstname, ...sampleData10 },
                            contactLastname:   { ...prevState.projectForm.contactLastname, ...sampleData11 },
                            contactEmail:      { ...prevState.projectForm.contactEmail, valid: true },
                            contactPassword:   { ...prevState.projectForm.contactPassword, valid: true }
                        },
                        ...prevState.formIsValid, formIsValid: true,
                        ...prevState.projectId, projectId: response.data.project._id
                    }));
                })
                .catch(error => {
                    console.log(error);
                })
        }
        sendVirtualPageView("Jetzt bewerben");
    }, [])


        const formElementsArray = [];

        for (let key in projectForm) {
            if (props.isAuthenticated) {
                if (projectForm[key].ifAuth === true) {
                    formElementsArray.push({
                        id: key, // Left part of projectForm objects (i.e. projectTitle)
                        config: projectForm[key] // Right part of projectForm objects (i.e. elementConfig{...})
                    });
                }
            } else {
                formElementsArray.push({
                    id: key, // Left part of projectForm objects (i.e. projectTitle)
                    config: projectForm[key] // Right part of projectForm objects (i.e. elementConfig{...})
                });
            }
        }

        let button = null;

        if(formIsValid) {
            button = (
                <Button color="primary" type="button" onClick={reviewHandler} className="anl__registration--preview">
                    {props.isAuthenticated ? 'Jetzt speichern' : 'Jetzt Vorschau ansehen'}
                </Button>
            );
        } else {
            button = (
                <Button type="button" color="primary" disabled>
                    Bitte prüfen Sie Ihre Angaben
                </Button>
            );
        }

        let form = (
            <Form onSubmit={onSubmitHandler}>
                <FormGroup row>
                    <Col sm={{size: 12}}>
                        <NewMediaUpload onUploadHandler={onUploadHandler}/>
                    </Col>
                </FormGroup>

                <Row>
                    {formElementsArray.map(formElement => (
                        <Input
                            key={formElement.id}
                            name={formElement.id}
                            elementType={formElement.config.elementType}
                            elementConfig={formElement.config.elementConfig}
                            {...(()=>{
                                // conditionally add the max length property to the Input element.
                                // will cause an indicator for the remaining characters to appear on page.
                                if (Object.hasOwn(formElement.config.validation, 'maxLength')) {
                                    return { maxLenght: formElement.config.validation.maxLength.value };
                                }
                                else {
                                    return {};
                                }
                            })()}
                            value={formElement.config.value}
                            changed={(event) => onChangeHandler(event, formElement.id)}
                            shouldValidate={formElement.config.validation}
                            touched={formElement.config.touched}
                            errorMessage={formElement.config.errorMessage}
                            tooltip={formElement.config.tooltip}
                            invalid={!formElement.config.valid}/>
                    ))}
                </Row>

                <FormGroup row>
                    <Col sm={{size: 12}}>
                        {button}
                    </Col>
                </FormGroup>

            </Form>
        );

        if (props.loading) {
            // Todo: Insert placeholder SVG
            form = "..."
        }

        let ctaRender = <CallToActionPlaceholder/>;

        if(cta){
            ctaRender = <CallToAction
                btnClass={`anl_cta--${cta.page}--btn-${cta.position}`}
                headline={cta.headline}
                copy={cta.copy}
                buttonText={cta.buttonText}
                buttonColor={cta.buttonColor}
                linkUrl={cta.linkUrl}
                outline={cta.outline}
                buttonVisibility={cta.buttonVisibility}
            />
        }

        return (
            <Aux>
                <Helmet>
                    <title>{`${process.env.REACT_APP_PROJECT_TITLE}: Jetzt bewerben`}</title>
                </Helmet>
                <Row>
                    <Col className="px-0">
                        <KeyVisual imageXs={KeyVisualXs} imageLg={KeyVisualLg} />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col xs="12" lg="9" className="py-3">
                        {ctaRender}
                    </Col>
                    <Col xs="12" lg="3" className="p-3">
                        <h3>Unser Bewerbungsservice</h3>
                        <a className="h1 anl_signup--telephone-number" href="tel:+4922167774683">0221 677 746 83</a>
                        <p>Montag bis Freitag <br/>9 Uhr bis 17 Uhr</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {form}
                        <Modal scrollable backdrop={'static'} className={classes.Modal} centered size='xl' isOpen={modal}>
                            <ModalHeader>Bitte überprüfen Sie Ihre Angaben</ModalHeader>
                            <ModalBody>
                                <Row>
                                    <Col style={{height: "fitContent"}} className="overflow-hidden" xl={4}>
                                        <SignUpPreview
                                            contestantName={projectForm.contestantName}
                                            projectTitle={projectForm.projectTitle}
                                            projectShortDescription={projectForm.projectShortDescription}
                                            mediaUrl={mediaObjects[0]}
                                        />
                                    </Col>
                                    <Col xl={8}>
                                        <SignUpPreviewList projectForm={projectForm} />
                                    </Col>
                                </Row>
                            </ModalBody>
                            <ModalFooter>
                                <Button outline color="secondary" onClick={modalHandler} className="anl__registration--modify">Bearbeiten</Button>{' '}
                                <Button color="secondary" onClick={onSubmitHandler} className="anl__registration--send">Jetzt absenden</Button>
                            </ModalFooter>
                        </Modal>
                    </Col>
                </Row>
            </Aux>
        );
    }

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        isAuthenticated: state.auth.token !== null,
        userId: state.auth.userId,
        token: state.auth.token
    }
};

export default connect(mapStateToProps)(SignUp);
